<template>
  <div class="form-elements">
    <div class="row">
      <div class="flex xs12">
        <vac-card :title="$t(`Edit group ${$route.params.id}`)">
          <form @submit.prevent="onSubmit" v-if="pageLoaded">

            <div class="row">
              <div class="flex md12 sm6 xs12">
                <va-input
                  v-model="name"
                  placeholder="Name"
                  class="input--bg"
                />
              </div>
            </div>

            <!--<div class="row">-->
            <!--<div class="flex md4 sm6 xs12">-->
            <!--<va-input-->
            <!--v-model="password"-->
            <!--type="password"-->
            <!--placeholder="Password"-->
            <!--class="input&#45;&#45;bg"-->
            <!--/>-->
            <!--</div>-->
            <!--</div>-->

            <div class="row">
              <div class="flex xs12">
                <va-tabs grow v-model="tabValue" style="width: 100%;">
                  <va-tab
                    v-for="title in tabTitles.slice()"
                    :key="title">
                    {{ title }}
                  </va-tab>
                </va-tabs>
              </div>
            </div>

            <div v-if="tabValue === 0">

              <div class="row">

                <div class="column flex md4 sm6 xs12"
                     v-for="(data, index) in users"
                     v-bind:key="index">

                  <div class="flex"
                       v-for="user in data"
                       v-bind:key="user.username">
                    <va-checkbox
                      :label="user.username"
                      v-model="usersCheckboxes"
                      :arrayValue="user.id"
                    />
                  </div>

                </div>

              </div>

            </div>
            <div v-if="tabValue === 1">
              <div class="row">
                <div class="column flex md4 sm6 xs12"
                     v-for="(data, index) in licenses"
                     v-bind:key="index">

                  <div class="flex"
                       v-for="license in data"
                       v-bind:key="license.name">
                    <va-checkbox
                      :label="license.name"
                      v-model="licensesCheckboxes"
                      :arrayValue="license.id"
                    />
                  </div>

                </div>
              </div>
            </div>

            <div v-if="tabValue === 2">

              <div class="row">

                <div class="column flex md4 sm6 xs12"
                     v-for="(data, index) in retailers"
                     v-bind:key="index">

                  <div class="flex"
                       v-for="retailer in data"
                       v-bind:key="retailer.name">
                    <va-checkbox
                      :label="retailer.name"
                      v-model="retailersCheckboxes"
                      :arrayValue="retailer.id"
                    />
                  </div>

                </div>

              </div>

            </div>

            <div v-if="tabValue === 3">

              <Permissions
                :permissions="permissions"
                :permissions-checkboxes="permissionsCheckboxes"
                :group-permissions="groupPermissions"
                :categories-permission-type="categoriesPermissionType"
                :categories-list="groupCategories"
                @assignProps="assignProps"
              />

            </div>

            <div v-if="tabValue === 4">
              <div class="tokens">
                <h1>Token: </h1>
                <p v-if="token">{{ token }}</p>
                <div class="buttons">
                  <va-button type="button" @click="generateToken" v-if="!token">Generate token</va-button>
                  <va-button type="button" @click="generateToken" v-else>Regenerate token</va-button>
                </div>
              </div>
            </div>

            <va-button class="btn--wide"
                       type="submit"
                       :style="{marginTop: '64px'}">
              Save
            </va-button>
          </form>
          <va-progress-bar v-if="loading"
                           indeterminate
                           :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"></va-progress-bar>
        </vac-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VaButton from 'vuestic-ui/src/components/vuestic-components/va-button/VaButton';
import HelperUser from "../../services/Helpers/HelperUser";
import { sortArrayOfObject } from "@/services/Helpers/HelperObject";
import { showToastError, showToastSuccess } from "@/services/Helpers/HelperToast";
import API from "../../services/API/API";
import Permissions from "@/components/groups/tabs/Permissions";

export default {
  name: 'Edit',
  components: { Permissions, VaButton },
  async created() {
    const userInfo = HelperUser.getUserLicensesRetailers();
    this.retailers = sortArrayOfObject(userInfo.retailers.data, "name");
    this.retailers = this.collectColumns(this.retailers);
    this.licenses = sortArrayOfObject(userInfo.licenses.data, "name");
    this.licenses = this.collectColumns(this.licenses);
    const promise = this.getUsers();

    await promise.then(() => {
      axios.get(`${process.env.VUE_APP_API_URL}/statistics-group/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        const group = response.data.data;
        this.id = group.id;
        this.name = group.name;
        this.groupLicenses = group.licenses;
        this.groupRetailers = group.retailers;
        this.groupUsers = group.users;
        this.groupPermissions = group.permissions
        this.groupCategories = group.categories
        this.initialCategories = group.categories.map(elem => elem.id)
        this.token = group.token

        group.retailers.forEach(retailer => {
          this.retailersCheckboxes.push(retailer.id);
        });

        group.licenses.forEach(license => {
          this.licensesCheckboxes.push(license.id);
        });

        group.users.forEach(user => {
          this.usersCheckboxes.push(user.id);
        });

        group.permissions.forEach(permission => {
          this.permissionsCheckboxes[permission.id] = true
        });

        this.categoriesPermissionType = !!this.permissionsCheckboxes[5]

      }).catch(error => console.error(error.message));
    });
    await this.getPermissions();
  },
  data() {
    return {
      id: null,
      name: '',
      retailersCheckboxes: [],
      licensesCheckboxes: [],
      usersCheckboxes: [],
      permissionsCheckboxes: {},
      groupLicenses: [],
      groupRetailers: [],
      groupUsers: [],
      groupPermissions: [],
      groupCategories: [],
      initialCategories: [],
      token: '',
      tabTitles: ['Users', 'Licenses', 'Retailers', 'Permissions', 'Tokens'],
      tabValue: 0,
      retailers: [],
      users: [],
      licenses: [],
      permissions: [],
      categoriesPermissionType: true,
      loading: true,
      pageLoaded: false,
    };
  },
  methods: {
    generateToken() {
      API.APIPost(`${process.env.VUE_APP_API_URL}/statistics-group/${this.$route.params.id}/token`, {},
        (res) => {
          this.token = res.token
          showToastSuccess('Token generated', this.$toast)
      }, () => {
        showToastError('Error in token generation', this.$toast)
      })
    },
    assignProps(key, value) {
      if (!!this?.[key] || typeof this[key] === "boolean") {
        this.$set(this, key, value);
      }
    },

    getUsers() {
      return new Promise(resolve => {
        API.APIGet(`${process.env.VUE_APP_API_URL}/users`, {}, data => {
          let sortUsers = sortArrayOfObject(data, "username");
          this.users = this.collectColumns(sortUsers);
          resolve();
        });
      });
    },

    getPermissions() {
      return new Promise(resolve => {
        API.APIGet(`${process.env.VUE_APP_API_URL}/permissions`, {}, data => {
          this.permissions = sortArrayOfObject(data, "name");
          this.pageLoaded = true;
          this.loading = false;
          resolve();
        });
      });
    },

    collectColumns(data) {
      const length = Math.ceil(data.length / 3);
      let output = [];
      for (let index = 0; index < 3; index++) {
        const multiplier = index * length;
        const innerArray = data.slice(multiplier, multiplier + length);
        output.push(innerArray);
      }
      return output;
    },

    onSubmit() {
      this.loading = true;

      const data = {
        group: {
          id: this.id,
          name: this.name,
        },
        licenses: [],
        brands: [],
        retailers: [],
        users: [],
        permissions: []
      };

      const retailers = this.retailersCheckboxes;
      const licenses = this.licensesCheckboxes;
      const users = this.usersCheckboxes;
      const permissions = Object.keys(this.permissionsCheckboxes);

      if (permissions.some(perm => perm === '4') && this.groupCategories.length === 0) {
        this.loading = false
        showToastError('Please select at least one allowed category', this.$toast)
        return
      }

      const deleteLicenses = [];
      const deleteRetailers = [];
      const deleteUsers = [];
      const deletePermissions = [];

      this.groupLicenses.map(license => {
        if (licenses.indexOf(license.id) === -1) {
          deleteLicenses.push(license.id);
        }
        return license;
      });

      this.groupRetailers.map(retailer => {
        if (retailers.indexOf(retailer.id) === -1) {
          deleteRetailers.push(retailer.id);
        }
        return retailer;
      });

      this.groupUsers.map(user => {
        if (users.indexOf(user.id) === -1) {
          deleteUsers.push(user.id);
        }
        return user;
      });

      this.groupPermissions.map(p => {
        if (permissions.indexOf(String(p.id)) === -1) {
          deletePermissions.push(parseInt(p.id));
        }
        return p;
      });

      data.deleteLicenses = deleteLicenses;
      data.deleteRetailers = deleteRetailers;
      data.deleteUsers = deleteUsers;
      data.deletePermissions = deletePermissions;

      data.licenses = licenses;
      data.retailers = retailers;
      data.users = users;
      data.permissions = permissions.map(p => parseInt(p));

      new Promise(resolve => {
        API.APIPut(`${process.env.VUE_APP_API_URL}/statistics-group/${this.$route.params.id}/remove-categories`, {
          categoryIds: this.initialCategories
        }, data => {
          resolve(data)
        })
      }).then(() => {
        API.APIPost(`${process.env.VUE_APP_API_URL}/statistics-group/${this.$route.params.id}/add-categories`, {
          categoryIds: this.groupCategories.map(elem => elem.id)
        }, () => {});
      });

      let saved = this.$store.dispatch('saveGroup', data);

      saved.then(() => {
        this.loading = false;
        showToastSuccess("Group saved success", this.$toast);
      }).catch(error => {
        this.loading = false;
        showToastError(error, this.$toast);
      });
    },
  },
};
</script>

<style lang="scss">
.row.row-inside {
  max-width: none;
}
.tokens {
  margin-top: 20px;
  h1 {
    margin-left: 8px;
  }
  p {
    margin-left: 8px;
  }
}
</style>
